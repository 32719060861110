// Dependencies
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';

// Hooks
import { useLogout } from './hooks/useLogout';
import { useAuthContext } from './hooks/useAuthContext';

// Components
import PlatformDataSummary from "./components/PlatformDataSummary";

// Pages
import ManageUsers from "./pages/users";
import SearchUser from "./pages/search-user";
import ManageProducts from "./pages/products";
import Appointments from "./pages/appointments";
import Orders from "./pages/orders";
import Admins from "./pages/admins";
import AuditLogs from "./pages/audit-logs";
import Login from "./pages/login";

import MainLogo from './assets/img/logo.png';


function App() {

  const { admin } = useAuthContext();
  
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
    window.location.href= "/";
  }

  return (
    <Router>

      {admin === null ? (
        <Routes>
          <Route path="/" element={<Login/>}/>
        </Routes>
      ):(
        <>
          {/* Header */}
          <section className="header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 px-2">
                  <button className="btn" onClick={() => setToggled(!toggled)}>
                    <span className="collapse-btn-icon"><i className="fa-sharp fa-solid fa-bars"></i></span>
                  </button>
                </div>
              </div>
            </div>
          </section>

          <div className="d-flex">
            <Sidebar
              transitionDuration={600}
              width="260px"
              backgroundColor="#0C0C0C"
              breakPoint="lg"
              onBackdropClick={() => setToggled(false)}
              toggled={toggled}
              rootStyles={{
                height: '100vh',
                zIndex: '110',
                border: 'none'
              }}
            > 
              <NavLink to="/">
                <div className="side-menu-brand-area">
                  <img className="side-menu-logo" src={MainLogo}></img>
                </div>
              </NavLink>

              <Menu
                renderExpandIcon={({ open }) => 
                  <span className="collapse-icon">
                    {open ? <i className="fa-duotone fa-caret-down mt-2"></i> : <i className="fa-duotone fa-caret-right mt-2"></i>}
                  </span>
                }
                rootStyles={{
                  ['.' + menuClasses.button]: {
                    backgroundColor: '#161616',
                    color: '#DCDCDC',
                    '&:hover': {
                      backgroundColor: '#a77629',
                    },
                  },
                  ['.active']: {
                    backgroundColor: '#a77629',
                  }
                }}
              >


                <SubMenu
                  icon={<span className="side-menu-icon"><i className="fa-duotone fa-users"></i></span>} 
                  label={<p className="side-menu-text">Customers</p>}
                > 
                  <MenuItem 
                    icon={<span className="side-menu-icon"><i className="fa-duotone fa-user-pen"></i></span>}
                    component={<NavLink to="/users" activeClassName={['menu-item'].active}/>}
                  > 
                    <p className="side-menu-text">Manage Details</p>
                  </MenuItem>
                  <MenuItem 
                    icon={<span className="side-menu-icon"><i className="fa-duotone fa-user-magnifying-glass"></i></span>}
                    component={<NavLink to="/search-user" activeClassName={['menu-item'].active}/>}
                  > 
                    <p className="side-menu-text">Search Customer</p>
                  </MenuItem>
                </SubMenu>

                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-clipboard-list-check"></i></span>}
                  component={<NavLink to="/appointments" activeClassName={['menu-item'].active}/>}
                > 
                  <p className="side-menu-text">Booking</p>
                </MenuItem>

                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-sharp-duotone fa-solid fa-microphone-stand"></i></span>}
                  component={<NavLink to="/live-band" activeClassName={['menu-item'].active}/>}
                  disabled
                > 
                  <p className="side-menu-text">Live Band<span className="side-menu-coming-soon ml-2">Soon</span></p>
                </MenuItem>

                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-badge-percent"></i></span>}
                  component={<NavLink to="/promotions" activeClassName={['menu-item'].active}/>}
                  disabled
                > 
                  <p className="side-menu-text">Promotions<span className="side-menu-coming-soon ml-2">Soon</span></p>
                </MenuItem>

                <MenuItem 
                  icon={<span className="side-menu-icon"><i class="fa-duotone fa-solid fa-martini-glass-citrus"></i></span>}
                  component={<NavLink to="/inventory" activeClassName={['menu-item'].active}/>}
                  disabled
                > 
                  <p className="side-menu-text">Liquor Inventory<span className="side-menu-coming-soon ml-2">Soon</span></p>
                </MenuItem>

                <MenuItem 
                  icon={<span className="side-menu-icon"><i class="fa-sharp fa-solid fa-file-chart-column"></i></span>}
                  component={<NavLink to="/report" activeClassName={['menu-item'].active}/>}
                  disabled
                > 
                  <p className="side-menu-text">Sales Report<span className="side-menu-coming-soon ml-2">Soon</span></p>
                </MenuItem>

                <SubMenu
                  icon={<span className="side-menu-icon"><i className="fa-duotone fa-gear"></i></span>} 
                  label={<p className="side-menu-text">System</p>}
                >
                  {/* <MenuItem 
                    icon={<span className="side-menu-icon"><i className="fa-duotone fa-bullhorn"></i></span>}
                  > 
                    <p className="side-menu-text">Announcement</p>
                  </MenuItem> */}
                  {/* <MenuItem 
                    icon={<span className="side-menu-icon"><i className="fa-duotone fa-image"></i></span>}
                  > 
                    <p className="side-menu-text">Manage Banners</p>
                  </MenuItem> */}
                  {/* <MenuItem 
                    icon={<span className="side-menu-icon"><i className="fa-duotone fa-building"></i></span>}
                  > 
                    <p className="side-menu-text">Company Details</p>
                  </MenuItem> */}
                  <MenuItem 
                    icon={<span className="side-menu-icon"><i className="fa-duotone fa-users-gear"></i></span>}
                    component={<NavLink to="/admins" activeClassName={['menu-item'].active}/>}
                  > 
                    <p className="side-menu-text">Admin</p>
                  </MenuItem>
                  <MenuItem 
                    icon={<span className="side-menu-icon"><i className="fa-sharp fa-solid fa-rectangle-list"></i></span>}
                    component={<NavLink to="/audit-logs" activeClassName={['menu-item'].active}/>}
                  > 
                    <p className="side-menu-text">Audit Log</p>
                  </MenuItem>
                </SubMenu>

                <MenuItem
                  className="menu-item"
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-right-from-bracket"></i></span>}
                  onClick={handleLogout}
                > 
                  <p className="side-menu-text">Logout</p>
                </MenuItem>

              </Menu>

            </Sidebar>

            <main className="main-content">
              <Routes>
                <Route path="/" element={<PlatformDataSummary/>}/>
                <Route path="/users" element={<ManageUsers/>}/>
                <Route path="/search-user" element={<SearchUser/>}/>
                <Route path="/products" element={<ManageProducts/>}/>
                <Route path="/appointments" element={<Appointments/>}/>
                <Route path="/orders" element={<Orders/>}/>
                <Route path="/admins" element={<Admins/>}/>
                <Route path="/audit-logs" element={<AuditLogs/>}/>
              </Routes>
            </main>

          </div>
        </>
      )}



    </Router>
  );
}

export default App;