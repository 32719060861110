import React, { useEffect, useState } from 'react';
import Moment from 'moment';

// hooks
import { useAuthContext } from '../hooks/useAuthContext';
import { useCreateTransaction } from '../hooks/useCreateTransaction';
import { useLogin } from '../hooks/useLogin';

const Users = () => {

	const { admin } = useAuthContext();
  	const { topUpCredit, deductCredit, setScoring, editPassword, setVolume, isLoading, error, actionSuccess, setActionSuccess } = useCreateTransaction();

  	const [selectedUser, setSelectedUser] = useState(null);
  	const [txnType, setTxnType] = useState(0);
  	const [amount, setAmount] = useState(0);

  	const createTransaction = async (e) => {
		e.preventDefault();
		if (txnType == 1) {
			var balanceAfter = Number(selectedUser.cashWallet) + Number(amount);
			await topUpCredit(admin.token, selectedUser._id, selectedUser.phone, amount, selectedUser.cashWallet, admin.username, balanceAfter);
			fetchUsers();
		} else if (txnType == 2) {
			var balanceAfter = Number(selectedUser.cashWallet) - Number(amount);
			await deductCredit(admin.token, selectedUser._id, selectedUser.phone, amount, selectedUser.cashWallet, admin.username, balanceAfter);
			fetchUsers();
		}
	};

	const [newPassword, setNewPassword] = useState('');
	const changePassword = async (e) => {
		e.preventDefault();
		await editPassword(admin.token, selectedUser._id, newPassword);
	};


	const [userData, setUserData] = useState();
	const fetchUsers = async () => {
		const response = await fetch('https://api.rome13.com/user', {
			headers: {
				'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setUserData(json);
		}
	}

	const freezeUser = async (_userID, _freezed) => {
		const response = await fetch('https://api.rome13.com/user/'+_userID, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${admin.token}`
            },
			body: JSON.stringify({
                freezed: !_freezed,
            })
        })
        // const json = await response.json();
		fetchUsers();
	};

	useEffect(() => {
		fetchUsers();
	}, []);

	return (
		<>
			{/* Create Transaction */}
			<div className="modal fade" id="transactionModal" tabindex="-1" role="dialog" aria-labelledby="transaction" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Create Transaction</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!actionSuccess ? (
							<div className="modal-body">
								{selectedUser !== null ? (
									<form className="w-100" onSubmit={createTransaction}>
									<div className="mb-2">
										<label htmlFor="txnType">
										<strong>Transaction Type:</strong>
										</label>
										<select 
										name='txnType'
										className='form-control'
										onChange={(e) => setTxnType(e.target.value)}
										>
										<option value="0">Choose Transaction</option>
										<option value="1">Top Up Credit</option>
										<option value="2">Deduct Credit</option>
										</select>
									</div>
									<div className="mb-2">
										<label htmlFor="amount">
										<strong>Amount:</strong>
										</label>
										<input
										type="number"
										step="0.01"
										placeholder='Enter transaction amount'
										autoComplete='off'
										name='amount'
										className='form-control'
										onChange={(e) => setAmount(e.target.value)}
										/>
									</div>
									{txnType == 0 ? (
										<></>
									):(
										<>
										<div className="mt-3">
										<label><strong>Confirm Transaction:</strong></label>
										{txnType == 1 ? <p className="">Top Up <strong><span className="profit-green">{parseFloat(amount).toFixed(2)}</span></strong> for <strong>{selectedUser.username}</strong></p> : null}
										{txnType == 2 ? <p className="">Deduct <strong><span className="error">{parseFloat(amount).toFixed(2)}</span></strong> for <strong>{selectedUser.username}</strong></p> : null}
										</div>
										<button disabled={isLoading} type="submit" className="btn setting-button w-100">
										<p className="text-white mb-0">Confirm</p>
										</button>
										{error && <div className="error">{error}</div>}
										</>
									)}

									</form>
								):(
									<p className="error mb-0">Failed to fetch user data. Please refresh page.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Transaction Success</h5>
								</div>
							</div>
						)}

					</div>
				</div>
			</div>

			{/* Change Password */}
			<div className="modal fade" id="changePasswordModal" tabindex="-1" role="dialog" aria-labelledby="changePassword" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">Change Password</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!actionSuccess ? (
							<div className="modal-body">
								{selectedUser != null ? (
									<form className="w-100" onSubmit={changePassword}>
										<div className="mb-2">
											<label htmlFor="newPW">
												<strong>New Password</strong>
											</label>
											<input
												type="password"
												placeholder='Enter new password'
												autoComplete='off'
												name='newPW'
												className='form-control'
												onChange={(e) => setNewPassword(e.target.value)}
											/>
										</div>
										<button disabled={isLoading} type="submit" className="btn setting-button w-100">
											<p className="text-white mb-0">Confirm</p>
										</button>
										{error && <div className="error">{error}</div>}
									</form>
								):(
									<p className="error mb-0">Failed to fetch user data. Please refresh page.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Password Change Success</h5>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Manage Customers</h3>
						<p className="mt-2 mb-0">Total {userData && userData.length} Customers</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">Phone</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Name</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Credit (MYR)</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Birthday</p></th>
									<th className="p-3" scope="col"><p className="mb-0">ReferCode</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Register Time</p></th>
								</tr>
							</thead>
							<tbody>
								{userData && userData.map((user, index) => (
									<tr key={index}>
										{/* <td className="px-3 py-3">
											<p className="mb-0">{userData[index].username}</p>
											<button className="btn setting-button  mt-2" data-toggle="modal" data-target="#changePasswordModal" onClick={(e) => {
												setActionSuccess(false);
												setSelectedUser(userData[index]);
											}}>
												<p className="mb-0"><i className="fa-solid fa-key mr-2"></i>Reset PW</p>
											</button>
										</td> */}
										<td className="px-3 py-3">
											<p className="mb-0">{userData[index].phone}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{userData[index].fullname}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{parseFloat(userData[index].cashWallet).toFixed(2)}</p>
											{/* <button className="btn setting-button  mt-2" data-toggle="modal" data-target="#transactionModal" onClick={(e) => {
												setActionSuccess(false);
												setSelectedUser(userData[index]);
												setAmount(0);
											}}>
												<p className="mb-0"><i className="fa-solid fa-pen-to-square mr-2"></i>Manage</p>
											</button> */}
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{userData[index].dob}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0" style={{fontSize:"14px"}}>{userData[index]._id}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0" style={{fontSize:"14px"}}>{Moment(userData[index].createdAt).format('YYYY/MM/DD HH:mm')}H</p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</>
  	);

};

export default Users;