import { useState } from 'react';

export const useUpdateProduct = () => {
    const [error, setError] = useState(null);
    const [actionSuccess, setActionSuccess] = useState(false);
    const [createSuccess, setCreateSuccess] = useState('');
    // const [isLoading, setIsLoading] = useState(null);

    const createNewProduct = async (authToken, newtitle, newPrice, newImage) => {
		const response = await fetch('https://api.rome13.com/product/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
			body: JSON.stringify({
                title: newtitle,
                price: newPrice,
                hidden: false,
                coverImgLink: newImage
            })
        })
        const json = await response.json();

        if (!response.ok) {
			setError(json.error);
		}
		if (response.ok) {
            setActionSuccess(true);
            setCreateSuccess("Successfully added new product!");
		}
	};

	const updateProductPrice = async (authToken, product, newtitle, newPrice) => {
		const response = await fetch('https://api.rome13.com/product/'+product._id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
			body: JSON.stringify({
                title: newtitle,
                price: newPrice,
            })
        })
        const json = await response.json();

        if (!response.ok) {
			setError(json.error);
		}
		if (response.ok) {
            setActionSuccess(true);
		}
	};

    const updateProductImage = async (authToken, product, newImageLink) => {
		const response = await fetch('https://api.rome13.com/product/'+product._id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
			body: JSON.stringify({
                coverImgLink: newImageLink,
            })
        })
        const json = await response.json();

        if (!response.ok) {
			setError(json.error);
		}
		if (response.ok) {
            setActionSuccess(true);
		}
	};


    return { createNewProduct, createSuccess, setCreateSuccess, updateProductPrice, updateProductImage, error, actionSuccess, setActionSuccess };
}