import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLogin } from '../hooks/useLogin';

import Logo from '../assets/img/logo.png';

const Login = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const {login, error, isLoading} = useLogin();

	const handleSubmit = async (e) => {
		e.preventDefault();
		await login(username, password);
	};

	return (
		<div className="full-page">
			<div className="container login-page form-page-padding">
				<div className="row form-bg rounded px-3 py-5 my-5">
					<div className="col-12">
						<img className="" src={Logo} style={{width:"100px"}}></img>
						<form className="mt-3" onSubmit={handleSubmit}>
							<div className="mb-2">
								<label htmlFor="username">
									<p className="text-gray mb-0"><strong>Admin ID:</strong></p>
								</label>
								<input
									type="text"
									placeholder='Enter Username'
									autoComplete='off'
									name='username'
									className='form-control'
									onChange={(e) => setUsername(e.target.value)}
								/>
							</div>
							<div className="mb-2">
								<label htmlFor="password">
								<p className="text-gray mb-0"><strong>Password:</strong></p>
								</label>
								<input
									type="password"
									placeholder='Enter Password'
									name='password'
									className='form-control'
									onChange={(e) => setPassword(e.target.value)}
								/>
							</div>
							<button disabled={isLoading} type="submit" className="btn main-button w-100 mt-3">
								<p className="text-white mb-0"><strong>Login</strong></p>
							</button>
							{error && <div className="error">{error}</div>}
						</form>
					</div>
				</div>
			</div>
		</div>
	);

};

export default Login;