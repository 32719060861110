import React, { useEffect, useState } from 'react';
import Moment from 'moment';

import { useOrder } from '../hooks/useOrder';
import { useAuthContext } from '../hooks/useAuthContext';

const Properties = () => {

	const { admin } = useAuthContext();
	const { updateOrderStatus, isLoading, error } = useOrder();

	const [selectedOrder, setSelectedOrder] = useState(null);
	const [statusOption, setStatusOption] = useState("");
	const [commAmount, setCommAmount] = useState(0);

	const [submitted, setSubmitted] = useState(false);

	const [userData, setUserData] = useState();
	const fetchUserData = async (selectedUsername) => {
		const response = await fetch('https://api.rome13.com/user/' + selectedUsername, {
			headers: {
			'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		setUserData(json);
		console.log("fetch",json);
	}

	const [ordersData, setOrdersData] = useState([]);
	const fetchOrdersData = async () => {
		const response = await fetch('https://api.rome13.com/order/', {
			headers: {
			'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		setOrdersData(json);
	}

	const createTransaction = async (e) => {
		e.preventDefault();
		if (statusOption === "") {
			return;
		}
		await updateOrderStatus(admin.token, admin.username, selectedOrder, statusOption, userData);
		fetchOrdersData();
		setSubmitted(true);
	};

	useEffect(() => {
		fetchOrdersData();
	}, []);

  	return (
		<>
			<div className="modal fade" id="updateOrderStatusModal" tabindex="-1" role="dialog" aria-labelledby="updateOrderStatus" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">Update Order Status</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!submitted ? (
							<div className="modal-body">
								{selectedOrder !== null ? (
									<form className="w-100" onSubmit={createTransaction}>
										<h6 className="mb-0">Order ID:</h6>
										<p>#{selectedOrder.orderID}</p>
										<h6 className="mb-0">Username:</h6>
										<p>{selectedOrder.username}</p>
										<h6 className="mb-0">Order Amount:</h6>
										<p>{parseFloat(selectedOrder.price).toFixed(2)}</p>
										<h6 className="mb-0">Total Amount:</h6>
										<p>{parseFloat(selectedOrder.totalAmount).toFixed(2)}</p>
										<div className="mb-2">
											<label>
											<strong>Update Status:</strong>
											</label>
											<select 
											className='form-control'
											onChange={(e) => setStatusOption(e.target.value)}
											>
												<option value="">Choose Status</option>
												<option value="Matching">Matching</option>
												<option value="Matched">Matched</option>
												<option value="Error">Error</option>
												<option value="Cancelled">Cancelled</option>
											</select>
										</div>
										{statusOption === "Matching" || statusOption === "Matched" || statusOption === "Error" || statusOption === "Cancelled" ? (
											<button type="submit" className="btn setting-button w-100 mt-3">
												<p className="text-white mb-0">Confirm</p>
											</button>
										):(
											<></>
										)}

									</form>
								):(
									<p className="error mb-0">Failed to fetch order data. Please refresh page.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Update Order Success</h5>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Manage Orders</h3>
						<p className="mt-2 mb-0">Total {ordersData && ordersData.length} Orders</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive-xl table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">Order ID</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Username</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Order Item</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Order Amount</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Status</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Date & Time</p></th>
								</tr>
							</thead>
							<tbody>
								{ordersData && ordersData.map((order, index) => (
									<tr key={index}>
										<td className="px-3 py-3">
											<p className="mb-0">#{order.orderID}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{order.username}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{order.item1 && "•B2B"}</p>
											<p className="mb-0">{order.item2 && "•B2C"}</p>
											<p className="mb-0">{order.item3 && "•Single Piece"}</p>
											<p className="mb-0">{order.item4 && "•Multi Piece"}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0"><strong>{parseFloat(order.totalAmount).toFixed(2)}</strong></p>
										</td>
										<td className="px-3 py-3">
											{order.status === "Matching" ? <p className="vip-yellow mb-0"><strong>Matching</strong></p> : null}
											{order.status === "Matched" ? <p className="profit-green mb-0"><strong>Matched</strong></p> : null}
											{order.status === "Error" ? <p className="error mb-0"><strong>Error</strong></p> : null}
											{order.status === "Cancelled" ? <p className="error mb-0"><strong>Cancelled</strong></p> : null}
											{order.status === "Matched" || order.status === "Cancelled" || order.status === "Error" ? (
												<></>
											):(
												<button className="btn setting-button  mt-2" data-toggle="modal" data-target="#updateOrderStatusModal" onClick={(e) => {
													setSubmitted(false);
													setStatusOption("");
													setSelectedOrder(order);
													fetchUserData(order.username);
												}}>
													<p className="mb-0"><i className="fa-solid fa-pen-to-square mr-2"></i>Update</p>
												</button>
											)}

										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{Moment(order.createdAt).format('YYYY/MM/DD HH:mm')}H</p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</>
  );

};

export default Properties;