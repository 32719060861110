import { useState } from 'react';
import { useAuthContext } from './useAuthContext';
import axios from "axios";
// import BifrostCors from "bifrost-cors";

export const useLogin = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const { dispatch } = useAuthContext();

    const login = async (username, password) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('https://api.rome13.com/admin/login', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({username, password})
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
            localStorage.setItem('chabevent_admin', JSON.stringify(json));
            dispatch({type: 'LOGIN', payload: json});
            setIsLoading(false);
            window.location.href = "/";
		}
    }

    const superLogin = async (authToken, userID) => {
        setError(null);
        const response = await fetch('http://localhost:8080/api/admin/superlogin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
			body: JSON.stringify({
                id: userID
            })
        })
        const json = await response.json();

        if (!response.ok) {
			setError(json.error);
		}
		if (response.ok) {
            // localStorage.setItem('chabevent_logged_in', JSON.stringify(json));
            // window.open("https://chabeventstore.com/", "_blank");
            // var DifferentWindow = window.open("https://chabeventstore.com/", "_blank");
            // axios.post("https://api.rome13.com/login", JSON.stringify(json))
            // .then((response) => {
            //     console.log(response.status, response.data.token);
            // });
		}
    }
    
    return { login, superLogin, isLoading, error };
}