import React, { useEffect, useState } from 'react';
import Moment from 'moment';

// hooks
import { useAuthContext } from '../hooks/useAuthContext';

const PlatformDataSummary = () => {

  const { admin } = useAuthContext();

  const [userData, setUserData] = useState();
	const fetchUsers = async () => {
		const response = await fetch('https://api.rome13.com/user', {
			headers: {
				'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setUserData(json);
		}
	}

	useEffect(() => {
		fetchUsers();
	}, []);


    return (
      <div className="container-fluid fluid-padding">
        <div className="row my-5">
          <div className="col-12">
            <h3 className="mb-0">System Status</h3>
            <p className="timestamp-text mb-0">Updated: {Moment(new Date()).format('DD/MM/YYYY HH:mm')}</p>
          </div>
          <div className="col-sm-6 col-lg-4 px-2 mt-3">
            <div className="data-box d-flex justify-contain-left">
              <div className="data-icon-box bg-gray">
                <span className="data-box-icon text-gray">
                  <i className="fa-solid fa-money-check-dollar-pen"></i>
                </span>
              </div>
              <div className="data-text-box">
                <h6 className="data-box-heading mb-0">Tasks</h6>
                <p className="data-box-number mb-0">-</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 px-2 mt-3">
            <div className="data-box d-flex justify-contain-left">
              <div className="data-icon-box bg-blue">
                <span className="data-box-icon text-gray">
                  <i className="fa-solid fa-users"></i>
                </span>
              </div>
              <div className="data-text-box">
                <h6 className="data-box-heading mb-0">Total Users</h6>
                <p className="data-box-number mb-0">{userData && userData.length}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 px-2 mt-3">
            <div className="data-box d-flex justify-contain-left">
              <div className="data-icon-box bg-yellow">
                <span className="data-box-icon text-gray">
                  <i className="fa-solid fa-triangle-exclamation"></i>
                </span>
              </div>
              <div className="data-text-box">
                <h6 className="data-box-heading mb-0">System Alert</h6>
                <p className="data-box-number mb-0">0</p>
              </div>
            </div>
          </div>
        </div>


        {/* <div className="row my-5">
          <div className="col-12">
            <h3 className="mb-0">Today's Summary</h3>
          </div>
          <div className="col-sm-6 col-lg-4 px-2 mt-3">
            <div className="data-box d-flex justify-contain-left">
              <div className="data-icon-box bg-dark-green">
                <span className="data-box-icon text-gray">
                  <i className="fa-regular fa-clipboard-list mx-2"></i>
                </span>
              </div>
              <div className="data-text-box">
                <h6 className="data-box-heading mb-0">Total Top Up</h6>
                <p className="data-box-number mb-0">0.00</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 px-2 mt-3">
            <div className="data-box d-flex justify-contain-left">
              <div className="data-icon-box bg-red">
                <span className="data-box-icon text-gray">
                <i className="fa-solid fa-money-bill-transfer"></i>
                </span>
              </div>
              <div className="data-text-box">
                <h6 className="data-box-heading mb-0">Withdrawal</h6>
                <p className="data-box-number mb-0">0.00</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 px-2 mt-3">
            <div className="data-box d-flex justify-contain-left">
              <div className="data-icon-box bg-dark-green">
                <span className="data-box-icon text-gray">
                <i className="fa-solid fa-chart-mixed-up-circle-dollar"></i>
                </span>
              </div>
              <div className="data-text-box">
                <h6 className="data-box-heading mb-0">Gross Profit</h6>
                <p className="data-box-number profit-green mb-0">0.00</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 px-2 mt-3">
            <div className="data-box d-flex justify-contain-left">
              <div className="data-icon-box bg-blue">
                <span className="data-box-icon text-gray">
                  <i className="fa-solid fa-users"></i>
                </span>
              </div>
              <div className="data-text-box">
                <h6 className="data-box-heading mb-0">New Users</h6>
                <p className="data-box-number mb-0">0</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );

}
  
export default PlatformDataSummary;